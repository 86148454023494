import React from 'react';

import Urls from '@/config/Urls';
import ErrorLogger from '@/ErrorLogger';
import * as Sentry from '@sentry/react';

import {HydratedRouter} from 'react-router/dom';
import {startTransition, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import {toTemporalInstant} from 'temporal-polyfill';
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from 'react-router';
import {handleGenericClientError} from '@/utils/errors';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';
import Environment from '@/config/Environment';

window._livanVersion = __GIT_HASH__;

Date.prototype.toTemporalInstant = toTemporalInstant;

ErrorLogger.Initialize(Sentry, {
  dsn: Urls.SentryDsn,
  replaysSessionSampleRate: 1,
  integrationsToAdd: [
    Sentry.extraErrorDataIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

window.addEventListener('error', (event) => {
  if (
    Environment.IsPlaywright &&
    event.error === null &&
    event.message?.startsWith('ResizeObserver loop completed with undelivered')
  ) {
    // weird error when running in playwright that we don't see in normal usage
    // @see https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    stopPropagationAndPreventDefault(event as any);
    return;
  }
  handleGenericClientError(event.error || new Error(event.message));
});

window.addEventListener('unhandledrejection', (event) => {
  handleGenericClientError(event.reason);
});

// voodoo to prevent react hydration errors. supposedly react 19 will remove the need for this
// @see https://github.com/remix-run/remix/issues/4822#issuecomment-2167950638
// @see https://gist.github.com/OnurGvnc/31f03f0d5237b78224aa083493fda645#file-clearbrowserextensioninjectionsbeforehydration-ts-L5-L40
function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        'html > *:not(body, head)',
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(', '),
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector('html')!,
      allowedAttributes: ['lang', 'dir', 'class'],
    },
    head: {
      $elm: document.querySelector('head')!,
      allowedAttributes: [''],
    },
    body: {
      $elm: document.querySelector('body')!,
      allowedAttributes: ['class'],
    },
  };

  Object.entries($targets).forEach(([targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}

function hydrate() {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    const root = hydrateRoot(document, <HydratedRouter />, {
      // attempt to fix hydration errors
      // https://github.com/remix-run/remix/issues/4822#issuecomment-2167950638
      onRecoverableError() {
        root.render(<HydratedRouter />);
      },
    });
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
